/** @format */

import React, { useContext } from "react";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/Context.js";
import { gql, useQuery } from "@apollo/client";

const queryParams = new URLSearchParams(window.location.search);
const orderTransactionID = queryParams.get("orderTransactionID");
// const productTitle = queryParams.get("productTitle");

const GET_TRACKING_URL_BY_ORDER_TRANSACTION_ID = gql`
query getTrackingURLByOrderTransactionID($orderTransactionID: String) {
  getTrackingURLByOrderTransactionID(orderTransactionID: $orderTransactionID)
}
`;

export default function TrackingURLRedirectPage() {
    const { setState, state } = React.useContext(Context);
    const { sendLog } = state;
    const { loading, data } = useQuery(GET_TRACKING_URL_BY_ORDER_TRANSACTION_ID, {
        variables: {
            orderTransactionID: orderTransactionID != null ? orderTransactionID : "",
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });
    const [redirectLink, setRedirectLink] = React.useState(null);


    React.useEffect(() => {
        if (loading === false && data != null && data.getTrackingURLByOrderTransactionID != null) {
            if (data.getTrackingURLByOrderTransactionID != null && data.getTrackingURLByOrderTransactionID.length > 0) {
                // shopify.toast.show('Subscribed to monthly plan');
                setRedirectLink(data.getTrackingURLByOrderTransactionID[0]);
            } else {
                // shopify.toast.show('Fail to subscribe to monthly plan');
                // setRedirectLink('/productSearch?subscriptionStatus=0');
                sendLog("MY_ORDERS_TRACKING_URL_REDIRECT_NO_URL", JSON.stringify({ orderTransactionID }));
            }
        }
    }, [loading])

    React.useEffect(() => {
        if (redirectLink != null) {
            window.top.location.href = redirectLink;
            sendLog("MY_ORDERS_TRACKING_URL_REDIRECT", JSON.stringify({ redirectLink }));
        }
    }, [redirectLink]);
    return (
        <Box sx={{
            width: "100%",
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
        }}
        >
            {
                loading || (data != null && data.getTrackingURLByOrderTransactionID != null && data.getTrackingURLByOrderTransactionID.length > 0) ?
                    <Box sx={{
                        height: "400px",
                        marginTop: 10,
                        display: "flex",
                        justifyContent: "center",
                    }}
                    >
                        <CircularProgress />
                    </Box> : <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxWidth: "700px",
                        gap: "10px"
                    }}>
                        <Box sx={{
                            background: 'url("/img/productDetailRedirect_logo.png") no-repeat 0 0',
                            backgroundSize: 'cover',
                            width: "100px",
                            height: "100px"
                        }} />
                        <Box sx={{
                            height: "50px",
                            fontSize: "28px",
                            fontWeight: "700",
                            lineHeight: "50px",
                            letterSpacing: "0.15px",
                            color: "#000000",
                            textAlign: 'center'
                        }}>The express information has not been updated, please wait patiently!</Box>
                    </Box>
            }
        </Box>
    );
}