/** @format */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./utils/SetRem.js";
import React, { useState, useEffect } from "react";
import AppSection from "./ProductSearchComponents/AppSection.react";
import AboutUs from "./AboutUs.react";
import FAQ from "./FAQ.react.js";
import ResetUserPassword from "./SignInComponents/ResetUserPassword.react";
import Setting from "./SettingComponents/Setting.react";
import { Context } from "./Context/Context";
import ShopifyInstall from "./ShopifyInstall.react";
import WixInstall from "./WixInstall.react";
import ShopifyRedirect from "./ShopifyRedirect.react";
import WixRedirect from "./WixRedirect.react";
import ShopifyAuth from "./ShopifyAuth.react";
import Privacy from "./Privacy.react";
import GoogleInstall from "./GoogleInstall.react";
import MyProducts from "./ProductsTabComponents/MyProducts.react";
import ImportProduct from "./ProductsTabComponents/ImportProduct.react";
import MyOrders from "./MyOrderComponents/MyOrders.react";
import AliExpressOrderLink from "./AliExpressOrderLink.react.js";
import { Box } from "@mui/system";
import SnackbarComponent from "./CommonComponents/SnackbarComponent.react";
import ProductEditDialog from "./CommonComponents/ProductEditDialog.react";
import { getCookie } from "doc-cookie";
import client from "@bigdatacloudapi/client";
import { v4 as uuid } from "uuid";
import { gql, useMutation } from "@apollo/client";
import IntermediatePage from "./IntermediatePage/IntermediatePage.react";
import DetailPage from "./DetailPage/DetailPage.react";
import ImportAliExpressURL from "./ImportAliExpressURL/ImportAliExpressURL.react";
import ExplanatoryVideoDialog from "./CommonComponents/ExplanatoryVideoDialog.react";
import Tutorial from "./Tutorial.react";
import Referral from "./Referral.react";
import Blog from "./Blog.react";
import ShopifySubscriptionRedirect from "./ShopifySubscriptionRedirect.react";
import SelectPlanDialog from "./CommonComponents/SelectPlanDialog.react";
import ExtensionInstructions from "./ExtensionComponents/ExtensionInstructions.react";
import MyOrderDetailPage from "./MyOrderComponents/MyOrderDetailPage.react"
import OneTimePurchase from "./OneTimePurchase.react";
import ShopifyOneTimePurchaseRedirect from "./ShopifyOneTimePurchaseRedirect.react";
import LoginQuestionnaire from "./CommonComponents/LoginQuestionnaire.react";
import ShopifyOneTimePurchaseSubscriptionRedirect from "./ShopifyOneTimePurchaseSubscriptionRedirect.react";
import SwitchAccount from "./SignInComponents/SwitchAccount.react";
import RefetchShopifyToken from "./CommonComponents/RefetchShopifyToken.react";
import ProductDetailRedirectPage from "./ProductSearchComponents/ProductDetailRedirectPage.react.js";
import AdminPage from "./AdminPage.react";
import ExternalAppRedirect from "./ExternalAppRedirect.react.js";
import TrackingURLRedirectPage from "./MyOrderComponents/TrackingURLRedirectPage.react.js";

const LOG = gql`
mutation log(
  $userEmail: String
  $eventName: String
  $eventValue: String
  $sessionID: String
  $IP: String
  $addOn: String
  $ref: String
  $platform: String
) {
  log(
    userEmail: $userEmail
    eventName: $eventName
    eventValue: $eventValue
    sessionID: $sessionID
    IP: $IP
    addOn: $addOn
    ref: $ref
    platform: $platform
  )
}
`;
const IPClient = client("bdc_115288e1d5334e08b3af0269d0f021f2");

export default function App() {
  const [IP, setIP] = useState("_");
  const [loading, setLoading] = useState(true);
  // Empty local story if cookie expires.
  const queryParams = new URLSearchParams(window.location.search);
  const isShopifyLogin = queryParams.get("isShopifyLogin");
  const token = getCookie("token");
  const [chromeExtensionIsInstall, setChromeExtensionIsInstall] = useState(localStorage.getItem("chromeExtensionIsInstall") !== "true" ? false : true);
  const [browserName, setBrowserName] = useState(sessionStorage.getItem("browserName") != null ? localStorage.getItem("browserName") : "none");
  useEffect(() => {
    if (
      isShopifyLogin == null &&
      (token == null ||
        token === "null" ||
        token === "undefined" ||
        token === "") &&
      localStorage.getItem("firstName") != null
    ) {
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("shopName");
      localStorage.removeItem("shopifyToken");
      window.location.href = "/";
    }

    // Obtain IP
    IPClient.getIpGeolocationFull()
      .then((jsonResult) => {
        setIP(jsonResult.ip + "/" + jsonResult.country.isoAlpha3);
      })
      .catch(function (error) {
        console.error('Asynchronous "then" error:', error);
      });
    if (localStorage.getItem("chromeExtensionIsInstall") !== "true") {
      fetch('chrome-extension://lnbknopcbaaajalpagleljjonlblliim/popup.html')
        .then(response => {
          setChromeExtensionIsInstall(true);
          localStorage.setItem("chromeExtensionIsInstall", "true");
        }).catch((e) => {
          setChromeExtensionIsInstall(false);
        });
    }

    if (sessionStorage.getItem("browserName") == null) {
      const userAgent = navigator.userAgent;
      let currentBrowerName = "none";
      switch (true) {
        case /chrome/i.test(userAgent):
          currentBrowerName = "chrome";
          break;
        case /firefox/i.test(userAgent):
          currentBrowerName = "firefox";
          break;
        case /safari/i.test(userAgent):
          currentBrowerName = "safari";
          break;
        case /edge/i.test(userAgent):
          currentBrowerName = "edge";
          break;
        case /trident/i.test(userAgent):
          currentBrowerName = "trident";
          break;
        default:
          currentBrowerName = "none";
      }
      sessionStorage.setItem("browserName", currentBrowerName);
      setBrowserName(currentBrowerName);
    }
  }, []);

  const currentComponentName = queryParams.get("currentComponentName");
  const isAccountPopUpDialogOpen = (currentComponentName == "SIGN_UP" ? token == null || token === "null" || token === "undefined" || token === "" || localStorage.getItem("firstName") == null : currentComponentName != null);
  const sessionID = uuid();
  const ref = queryParams.get("ref");
  const firstName =
    localStorage.getItem("firstName") === "null"
      ? null
      : localStorage.getItem("firstName");

  const [log] = useMutation(LOG);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  );
  const sendLog = (eventName, eventValue, addOn) => {
    log({
      variables: {
        userEmail: localStorage.getItem("email") === "null"
          ? null
          : localStorage.getItem("email"),
        eventName,
        eventValue,
        sessionID,
        IP,
        addOn,
        ref,
        platform: isMobile ? 'mobile' : 'PC',
      },
    });
  }
  if (loading && IP !== '_') {
    setLoading(false);
  }
  const [state, setState] = useState({
    userID:
      localStorage.getItem("userID") === "null"
        ? '0'
        : localStorage.getItem("userID"),
    firstName,
    shopifyToken:
      localStorage.getItem("shopifyToken") === "null"
        ? null
        : localStorage.getItem("shopifyToken"),
    email:
      localStorage.getItem("email") === "null"
        ? null
        : localStorage.getItem("email"),
    shopName:
      localStorage.getItem("shopName") === "null"
        ? null
        : localStorage.getItem("shopName"),
    title: "",
    openSnackbar: false,
    snackbarText: "",
    snackbarState: null,
    currentComponentName,
    isAccountPopUpDialogOpen,
    isOpenImportProductDialog: false,
    tabValue: 0,
    pushSuccessList: [],
    currentClickProduct: {
      id: null,
      title: "",
      thumbnailSource: "",
      shopifyProductID: ''
    },
    handleRefetchProductList: null,
    sessionID,
    IP,
    sendLog,
    isPushingToShopify: false,
    productListAfterEdit: [],
    isOpenImportAliExpressURLDialog: false,
    isExplanatoryVideoDialogOpen: false,
    explanatoryVideoSrc: '',
    handleRefetchMyProductsCount: null,
    isEditShopifyInput: false,
    handleRefetchImportState: null,
    openEditSnackbar: false,
    editSnackbarText: "",
    editSnackbarState: null,
    variantsIsWrong: false,
    imagesIsWrong: false,
    myProductListCount: 1,
    isOpenSelectPlanDialog: false,
    FreeShowProductList: [],
    currentFailedProduct: [],
    setPushSuccessIDList: null,
    setPushFailedIDList: null,
    setPushFailedReasonList: null,
    setCurrentFailedCount: null,
    setPushStatus: null,
    pushFailedIDList: null,
    pushFailedReasonList: null,
    setPushIDList: null,
    isOpenYellowBanner: localStorage.getItem("isShowIpopifyLimitedTimeBanner") != "false" && false ? true : false,
    isLoginQuestionnaireOpen: false,
    isOpenRatingDialog: false,
    storePlatform: "shopify",
    wixSiteID: null,
    shipToCountry: "US",
    isOpenSwitchAccountDialog: false,
    isOpenRefetchTokenDialog: false,
    adjustedEmail: null,
    chromeExtensionIsInstall,
    browserName
  });

  useEffect(() => {
    setState({ ...state, IP, sendLog })
  }, [loading]);



  return (
    <Box>
      <Context.Provider value={{ state, setState }}>
        <BrowserRouter>
          <SnackbarComponent />
          {/* <ProductEditDialog /> */}
          <ImportAliExpressURL />
          <SwitchAccount />
          <RefetchShopifyToken />
          <ExplanatoryVideoDialog />
          <SelectPlanDialog />
          <LoginQuestionnaire />
          <Routes>
            <Route
              exact
              path="/app"
              element={
                <React.Suspense fallback={null}>
                  <AppSection isShowPopup={false} currentPopup={null} />
                </React.Suspense>
              }
            />
            <Route
              path="/faq"
              element={
                <React.Suspense fallback={null}>
                  <FAQ />
                </React.Suspense>
              }
            />
            <Route
              path="/about"
              element={
                <React.Suspense fallback={null}>
                  <AboutUs />
                </React.Suspense>
              }
            />
            <Route
              path="/signin"
              element={
                <React.Suspense fallback={null}>
                  <AppSection isShowPopup={true} currentPopup={"SignIn"} />
                </React.Suspense>
              }
            />
            <Route
              path="/signup"
              element={
                <React.Suspense fallback={null}>
                  <AppSection isShowPopup={true} currentPopup={"SignUp"} />
                </React.Suspense>
              }
            />
            <Route
              path="/setting"
              element={
                <React.Suspense fallback={null}>
                  <Setting />
                </React.Suspense>
              }
            />
            <Route
              path="/retrieve"
              element={
                <React.Suspense fallback={null}>
                  <ResetUserPassword />
                </React.Suspense>
              }
            />
            <Route
              path="/shopifyInstall"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyInstall />
                </React.Suspense>
              }
            />
            <Route
              path="/wixInstall"
              element={
                <React.Suspense fallback={null}>
                  <WixInstall />
                </React.Suspense>
              }
            />
            <Route
              path="/shopifyRedirect"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyRedirect />
                </React.Suspense>
              }
            />
            <Route
              path="/wixRedirect"
              element={
                <React.Suspense fallback={null}>
                  <WixRedirect />
                </React.Suspense>
              }
            />
            <Route
              path="/shopifyAuth"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyAuth />
                </React.Suspense>
              }
            />
            <Route
              path="/googleInstall"
              element={
                <React.Suspense fallback={null}>
                  <GoogleInstall />
                </React.Suspense>
              }
            />
            <Route
              path="/privacy"
              element={
                <React.Suspense fallback={null}>
                  <Privacy />
                </React.Suspense>
              }
            />
            <Route
              path="/myProducts"
              element={
                <React.Suspense fallback={null}>
                  <MyProducts />
                </React.Suspense>
              }
            />
            <Route
              path="/myOrders"
              element={
                <React.Suspense fallback={null}>
                  <MyOrders />
                </React.Suspense>
              }
            />
            <Route
              path="/importProduct"
              element={
                <React.Suspense fallback={null}>
                  <ImportProduct />
                </React.Suspense>
              }
            />
            <Route
              path="/orderLink"
              element={
                <React.Suspense fallback={null}>
                  <AliExpressOrderLink />
                </React.Suspense>
              }
            />
            <Route
              path="/oneTimePurchase"
              element={
                <React.Suspense fallback={null}>
                  <OneTimePurchase />
                </React.Suspense>
              }
            />
            <Route
              path="/"
              element={
                <React.Suspense fallback={null}>
                  <IntermediatePage />
                </React.Suspense>
              }
            />
            <Route
              path="/detail"
              element={
                <React.Suspense fallback={null}>
                  <DetailPage />
                </React.Suspense>
              }
            />
            <Route
              path="/tutorial"
              element={
                <React.Suspense fallback={null}>
                  <Tutorial />
                </React.Suspense>
              }
            />
            <Route
              path="/referral"
              element={
                <React.Suspense fallback={null}>
                  <Referral />
                </React.Suspense>
              }
            />
            <Route
              path="/blog"
              element={
                <React.Suspense fallback={null}>
                  <Blog />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/testplan"
              element={
                <React.Suspense fallback={null}>
                  <SelectPlanDialog isShowPopup={false} currentPopup={null} />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/shopifySubscriptionRedirect"
              element={
                <React.Suspense fallback={null}>
                  <ShopifySubscriptionRedirect isShowPopup={false} currentPopup={null} />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/shopifyPurchaseRedirect"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyOneTimePurchaseRedirect />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/extensionInstructions"
              element={
                <React.Suspense fallback={null}>
                  <ExtensionInstructions isShowPopup={false} currentPopup={null} />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/orderDetail"
              element={
                <React.Suspense fallback={null}>
                  <MyOrderDetailPage isShowPopup={false} currentPopup={null} />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/shopifyPurchaseSubscriptionRedirect"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyOneTimePurchaseSubscriptionRedirect />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/productDetailRedirect"
              element={
                <React.Suspense fallback={null}>
                  <ProductDetailRedirectPage isShowPopup={false} currentPopup={null} />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/admin"
              element={
                <React.Suspense fallback={null}>
                  <AdminPage isShowPopup={false} currentPopup={null} />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/externalApp"
              element={
                <React.Suspense fallback={null}>
                  <ExternalAppRedirect isShowPopup={false} currentPopup={null} />
                </React.Suspense>
              }
            />
            <Route
              path="/Tracking"
              element={
                <React.Suspense fallback={null}>
                  <TrackingURLRedirectPage />
                </React.Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    </Box>
  );
}
